<template>
  <div style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h2 class="mt-3 ml-3 title">Kunden CRM System</h2>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12">
          <TableFullCustomerCrm @tableCreated="tableCreated" @getId="getId"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import TableFullCustomerCrm from "@/components/Tables/TableFullCustomerCrm";

export default {
  components: { TableFullCustomerCrm },
  data() {
    return {
      preloader: true,
      search: {
        search: null,
        search_select: 'identification'
      },
      search_from: [
        {
          text: 'Identification',
          value: 'identification'
        },
        {
          text: 'First name',
          value: 'first_name'
        },
        {
          text: 'Last name',
          value: 'last_name'
        },
        {
          text: 'Birthday',
          value: 'birthday'
        }
      ],
      customers: [],
      meta: {},
      customer_id: null
    }
  },
  methods: {
    tableCreated(bool) {
      this.preloader = !bool
    },
    getId(id) {
      this.customer_id = id
    },
    mxPreloader() {
      this.preloader = false
    }
  }
}
</script>
